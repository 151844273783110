import { html } from 'lit';
import { BaseCustomElement } from '../base-custom-element/base-custom-element';

class LfFilterStatus extends BaseCustomElement {
    static get properties() {
        return {
            data: { type: Object },
            hasError: { type: Boolean },
            isLoading: { type: Boolean },
            isUserInteraction: { type: Boolean },
            itemsCount: { type: Number },
            i18n: { type: Object}

        };
    }

    constructor() {
        super();
        this.data = {};
        this.hasError = false;
        this.isLoading = false;
        this.isUserInteraction = false;
        this.itemsCount = 0;

        this.i18n = {};
        this.locale = 'de';
    }

    _getStatusText() {
        if (this.itemsCount !== 1) return this.i18n.results;
        return this.i18n.results === 'Ergebnisse' ? 'Ergebnis' : 'result'
    }

    render() {
        return this.isLoading ? '...'  : this.itemsCount + ' ' + this._getStatusText();

    }
}

customElements.define('lf-filter-status', LfFilterStatus);
