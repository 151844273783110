import { html, nothing } from 'lit';
import { BaseCustomElement } from '../base-custom-element/base-custom-element';
import '../button/button.js';

class LfFilterFooter extends BaseCustomElement {
    static get properties() {
        return {
            hasError: { type: Boolean },
            isLoading: { type: Boolean },
            numberOfEntries: { type: Number, attribute: 'results' },
        };
    }

    constructor() {
        super();
        this.numberOfEntries = 0;
        this.icon = '';
        this.hasError = false;
        this.isLoading = false;
        this.isUserInitiated = false;
        this.i18n = {};
        this.locale = 'de';
        this._onApiRequest = this._onApiRequest.bind(this);
        this._onApiResponse = this._onApiResponse.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        this.resultsElement = document.querySelector('#filter-result');
        window.addEventListener('api-request', this._onApiRequest);
        window.addEventListener('api-response', this._onApiResponse);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('api-request', this._onApiRequest);
        window.removeEventListener('api-response', this._onApiResponse);
    }

    _onFilterButtonClick() {
        this.resultsElement.scrollIntoView(true);
    }

    _onApiRequest() {
        this.isLoading = true;
    }

    _onApiResponse(event) {
        this.isLoading = false;
        this.numberOfEntries = event.detail.data.pagination.numberOfEntries;
        this.isUserInitiated = event.detail.isUserInitiated;
        this.i18n = event.detail.data.i18n;
        this.locale = event.detail.data.locale;
    }

    render() {
        if (this.numberOfEntries && this.numberOfEntries < 1) {
            return nothing;
        }

        const results_de = 1 !== this.numberOfEntries ? "Ergebnisse" : "Ergebnis";
        const results_en= 1 !== this.numberOfEntries ? "results" : "result";

        const text_en = `${this.i18n.showResults} ${this.numberOfEntries} ${results_en}`;

        const text_de = `${this.numberOfEntries} ${results_de} ${this.i18n.showResults} `;


        const buttonText = this.locale === 'de' ? text_de : text_en;

        return html`
            <lf-button
                .text=${buttonText}
                .isLoading=${this.isLoading && this.isUserInitiated}
                @click=${this._onFilterButtonClick}
            ></lf-button>
        `;
    }
}

customElements.define('lf-filter-footer', LfFilterFooter);
